


import {Component, Vue, Watch, Ref} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {TNote} from '@/_types/store/note.type';
import {Validations} from 'vuelidate-property-decorators';
import {TVuelidateRuleSet} from '@/_types/vuelitation-rule-set.type';
import {maxLength, required} from 'vuelidate/lib/validators';
import {TRequestNote} from '@/_api/notes/notes.api';
import {TUser} from '@/_types/user.type';
import {TApiListResponse} from '@/_types/api/api-list-response.type';
import {TContact} from '@/_types/contact.type';
import {TOpenEwSharerPayload} from '@/_store/ew-sharer.store';
import {TTab} from '@/_ui/tabs/types/tabs.type';
import SearchBar from '@/_components/search-bar/search-bar.vue';
import SegmentControl from '@/_ui/segment-control/segment-control.vue';
import iconShare from '@/_modules/icons/components/icon-share.vue';
import iconStar from '@/_modules/icons/components/icon-star.vue';
import IconEwStar from '@/_modules/icons/components/icon-ew-star.vue';
import switchCheckbox from '@/views/components/parts/switch-checkbox.vue';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import PageTitle from '@/_components/page-title/page-title.vue';

type TNoteQuery = {
  offset: number;
  limit: number;
}

@Component({
  components: {
    SearchBar,
    PageTitle,
    SegmentControl,
    IconEwStar,
    iconShare,
    iconStar,
    switchCheckbox,
    EwAvatar,
  },
})
export default class Notes extends Vue {

  @Ref('cardCreate') cardCreate: HTMLElement;

  @Getter('_userStore/user') userInfo: TUser;
  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('notesStore/noteList') noteList: TApiListResponse<TNote>;
  @Getter('notesStore/isNoteLoading') isNoteLoading: boolean;

  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName?: string}) => void;
  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;

  public sharerPreviousId: number;

  public noteListSegmentTabs: TTab[] = [
    {
      title: this.$t('navigation.all'),
      isActive: true,
      index: 0,
    },
    {
      title: this.$t('navigation.my'),
      isActive: false,
      index: 1,
    },
  ];

  @Validations()
  public readonly validations: TVuelidateRuleSet<TNote> = {
    formData: {
      title: {
        required,
      },
      text: {
        required,
        maxLength: maxLength(2000)
      },
    },
    createFormData: {
      title: {
        required,
      },
      text: {
        required,
        maxLength: maxLength(2000)
      },
    },
  }

  public toggledId: number = null;
  public isCollapsed: boolean = true;
  public isCreateNewNoteFormVisible: boolean = false;
  public isEditingANote: boolean = false;
  public noteId: number = null;
  public isActive: boolean = false;
  public query: TNoteQuery = {
    offset: 0,
    limit: 20,
  };
  public type: string = 'all';
  public formData: TRequestNote = {
    title: '',
    text: '',
    is_private: true,
  };
  public createFormData: TRequestNote = {
    title: '',
    text: '',
    is_private: true,
  };
  public user_id: number = null;
  public isSendLoading: boolean = false;
  public onChangeLoading: boolean = false;

  public get getNoteList(): TApiListResponse<TNote> {
    return this.noteList;
  }

  public get isLoading(): boolean {
    return this.isNoteLoading;
  }

  public get getEventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  @Watch('isEditingANote')
  public onEditChange(): void {
    if (this.isEditingANote) {
      this.setData();
    }
  }

  @Watch('userInfo', {immediate: true})
  public onUserInfoChange(): void {
    if (!this.userInfo || !this.userInfo.id) {
      return;
    }
    this.callNoteList();
    this.user_id = this.userInfo.id;
  }

  public onCreateClick(): void {
    this.isCreateNewNoteFormVisible = true;
  }

  public setNoteIsPrivate($event: boolean): void {
    this.createFormData.is_private = !$event;
  }

  public async callNoteList(): Promise<void> {
    if (this.type === 'own') {
      await this.$store.dispatch('notesStore/notesMy', {
        queryParams: {...this.query}, eventId: this.getEventId
      });
    } else if (this.type === 'all') {
      await this.$store.dispatch('notesStore/notesAll', {
        queryParams: {...this.query}, eventId: this.getEventId
      });
    }

    this.isCreateNewNoteFormVisible = false;
    this.isEditingANote = false;
    this.noteId = null;
  }

  public onEditNoteClick(noteId: number): void {
    this.isEditingANote = true;
    this.noteId = noteId;
  }

  public async addFav(id: number): Promise<void> {
    await this.$store.dispatch('notesStore/addFav', id);
    await this.callNoteList();
  }

  public async removeFav(id: number): Promise<void> {
    await this.$store.dispatch('notesStore/removeFav', id);
    await this.callNoteList();
  }

  public async onRemoveNoteClick(id: number): Promise<void> {
    this.noteId = id;
    this.onChangeLoading = true;
    await this.$store.dispatch('notesStore/removeNote', id);
    await this.callNoteList();
    this.onChangeLoading = false;
  }

  public transformDate(val: string): string {
    return this.$moment(this.$moment(val)).format('DD MMM YYYY');
  }

  public transformTime(val: string): string {
    return this.$moment(this.$moment(val)).format('HH:mm');
  }

  public onNoteListSegmentActiveTabUpdate(activeTab: TTab, activeTabIndex: number): void {
    this.noteListSegmentTabs.forEach((tab, idx) => {
      tab.isActive = idx === activeTabIndex;
    });

    this.dispatchSegmentTabClickHandlers();
  }

  public dispatchSegmentTabClickHandlers(): void {
    if (this.noteListSegmentTabs.findIndex(tab => tab.isActive) === 1) {
      this.type = 'own';
    } else {
      this.type = 'all';
    }

    this.query.offset = 0;
    this.query.limit = 10;
    this.callNoteList();
  };

  public getTruncatedText(text: string, length: number): string {
    if (!text || !length || length <= 0 || text.length <= length) {
      return text;
    }
    return `${text.substring(0, length)}...`;
  }

  public linkifyUrls(inputString: string): string {
    return ChatHelper.createLinks(inputString);
  }

  public async editNote(): Promise<void> {
    this.$v.formData.$touch();

    if (this.$v.formData.$pending || this.$v.formData.$invalid) {
      return;
    }

    this.onChangeLoading = true;
    await this.$store.dispatch('notesStore/editNote', {queryParams: this.formData, noteId: this.noteId});
    await this.callNoteList();
    this.isEditingANote = false;
    this.onChangeLoading = false;
  }

  public setData(): void {
    const note = this.getNoteList.List.filter((item) => {
      return item.id === this.noteId;
    });
    this.formData.title = note[0].title;
    this.formData.text = note[0].text;
    this.formData.is_private = note[0].is_private;
  }

  public async createNote(): Promise<void> {
    this.$v.createFormData.$touch();

    if (this.$v.createFormData.$pending || this.$v.createFormData.$invalid) {
      return;
    }

    this.isSendLoading = true;
    await this.$store.dispatch('notesStore/addNote', {eventId: this.getEventId, queryParams: this.createFormData});
    await this.callNoteList();
    this.isSendLoading = false;
    this.createFormData.title = '';
    this.createFormData.text = '';
    this.createFormData.is_private = false;
    this.isCreateNewNoteFormVisible = false;
  }

  public setShareNoteId(id: number): void {
    this.noteId = id;
  }

  public removeShareNoteId(): void {
    if (!this.isEditingANote) {
      this.noteId = null;
    }
  }

  public share(event: KeyboardEvent, id: number): void {
    if (this.sharerPreviousId === id) {
      this.closeSharer();
    } else {
      this.openSharer({
        eventTarget: event.target as Element,
      });
    }
    this.sharerPreviousId = id;
  }

  public loadMore(): void {
    if (this.query.limit <= this.getNoteList.Total) {
      this.query.limit += 20;
      this.callNoteList();
    }
  }

  public onContactClick(contactId: number): void {
    if (this.myself.id === contactId) {
      return;
    }

    this.openContactCard({
      contactId,
    });
  }

  public showText(id: number): void {
    this.toggledId = id;
  }

  public hideText(): void{
    this.toggledId = null;
  }

  public onCreateNewClick(): void {
    this.isCreateNewNoteFormVisible = true;
    window.setTimeout(this.highlightCreateCard, 100);
  }

  public highlightCreateCard(): void {
    this.cardCreate.classList.add('card-highlighted');
  }

  public hideCreateForm(): void {
    this.isCreateNewNoteFormVisible = false;
  }

  public onCancelCreate(): void {
    this.cardCreate.classList.remove('card-highlighted');
    window.setTimeout(this.hideCreateForm, 310);
  }
}
